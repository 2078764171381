
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import GymNewButton from "~/components/utils/form-components/GymNewButton";
import CustomLoader from "@/components/utils/CustomLoader";
import ApiService from "@/core/services/api-interaction/ApiService";
import {PopupHelper} from "@/core/helpers/PopupHelper";

const CUSTOMER = 'customer';
const AFFILIATE = 'affiliate';

export default {
    name: "CustomerAffiliateRoleModal",
    components: {CustomLoader, GymNewButton, VueSlickCarousel},
    props: {
        popupVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            affiliateTos: false,
            carouselSettings: {
                speed: 500,
                infinite: false,
                slidesToShow: 2,
                slidesToScroll: 2,
                touchThreshold: 2,
                dots: true,
                arrows: false,
                variableWidth: false,
                responsive: [
                    {
                        breakpoint: 1080,
                        settings: {
                            variableWidth: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            windowWidth: window.innerWidth,
            CUSTOMER,
            AFFILIATE,
            isSelectRoleLoading: false
        };
    },

    computed: {
        customerBenefits() {
            return [
                'NFT Turbo',
                'Purchase Digital Land',
                'Participate in Partnership Deals',
                'Receive minting Rewards',
            ];
        },
        affiliateBenefits() {
            return [
                'Placement in Unilevel',
                'Placement in Binary',
                'CV Points',
                'Binary Commissions based on the rank',
                'Matching bonuses',
                'Leadership Incentives',
                'Product Autoship Incentive',
            ];
        },
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    methods: {
        showAffiliateTOSPopup (isChecked) {
            if (isChecked) {
                this.$store.dispatch('application/popup-control/setAffiliateTmsPopupData', {
                    standalone: false,
                    class: 'select-your-role'
                });
            }
        },

        handleModalClose() {
            this.$emit('handleClose');
        },

        onResize() {
            this.windowWidth = window.innerWidth;
        },

        async handleSelectRole(type) {
            this.isSelectRoleLoading = true;
            try {
                const roleResponse = await ApiService.patch(`${this.$config.binaryTreeApi}/api/users`, {
                    is_referral_link_active: type === AFFILIATE
                })

                const responseBinary = await ApiService.get(`${this.$config.binaryTreeApi}/api/users`);

                PopupHelper.showSuccessNotification(this.$t(roleResponse?.data?.message))

                this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                    isRoleSelected: true,
                    isReferralLinkActive: type === AFFILIATE,
                    subscriptionExpiresAt: responseBinary?.data?.subscription_expires_at,
                    totalOwnPurchaseAmount: responseBinary?.data?.user?.totalOwnPurchaseAmount,
                }, {root: true});
                this.$router.push('/nfts/products');
            } catch (e) {
                console.error(e);
            } finally {
                this.isSelectRoleLoading = false;
            }
        }
    },
};
