
import {copyToClipboard, getHidedAccountAddress} from "~/core/helpers/GlobalHelpers";
import {MetaWorldManager} from "@/core/services/map/MetaWorldManager";
import {PopupHelper} from "@/core/helpers/PopupHelper";
import { METAVERSES } from "~/core/services/utils/MetaverseConstants";
import { mapGetters, mapActions } from "vuex";
import { WALLET_ERROR_CODES } from "~/core/services/utils/Constants";
import { ETH_CONTRACTS } from "~/contracts/addresses";
import { BinanceSmartChainNetwork, EthereumNetwork } from "~/pages/finances/wallet/constants/WalletNetworkConstants";

export default {
    name: "ContractAddressesDropDown",
    data() {
        return {
            showDropdown: false,
            getHidedAccountAddress
        }
    },
    props: {
        authUser: {
            type: Object,
            default: null
        }
    },
    methods: {
        ...mapActions({
            requestToChangeChain: 'application/driver/requestToChangeChain',
            requestToAddChain: 'application/driver/requestToAddChain',
        }),
        closeDropdown() {
            this.showDropdown = false
        },
        handleCopyWalletAddress(account) {
            copyToClipboard(account);
        },
        async onAddClick(token) {
            const ethereum = MetaWorldManager.sharedInstance().ethereum();


            let isOnNeededChain = false;

            try {
                await this.requestToChangeChain(token.network.chainId);
                isOnNeededChain = true;
            } catch (e) {
                if (e.code === WALLET_ERROR_CODES.USER_REJECTED) {
                    return;
                } else {
                    console.error(e);
                    isOnNeededChain = false;
                }
            }

            if (!isOnNeededChain) {
                try {
                    await this.requestToAddChain(token.network);
                    isOnNeededChain = true;
                } catch (e) {
                    if (e.code === WALLET_ERROR_CODES.USER_REJECTED) {
                        isOnNeededChain = false;
                    } else {
                        console.error(e);
                    }
                }
            }

            if (!isOnNeededChain) {
                PopupHelper.showErrorAlert(this.$t('Failed to add address to your wallet'));
                return;
            }

            await ethereum.sendAsync({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: token.address,
                        symbol: token.symbol,
                        decimals: token.decimals,
                        image: token.image,
                    },
                },
            }, (err, added) => {
                if (err) {
                    let message = 'Failed to add address to your wallet';
                    if(err.code === WALLET_ERROR_CODES.USER_REJECTED) {
                        message = 'User rejected the request';
                    } else {
                        console.error('Unknown error at ContractAddressesDropDown.vue onAddClick', err);
                    }
                    PopupHelper.showErrorAlert(this.$t(message));
                } else if (added) {
                    PopupHelper.showSuccessNotification(this.$t('Address has been successfully added to your wallet'));
                }
            });
        },
    },
    computed: {
        ...mapGetters('map/mapModule', ['metaverseId']),
        contractAddress() {
            const mwm = MetaWorldManager.sharedInstance();
            return [
                {
                    icon: require('~/assets/images/wallets/gymnet.svg'),
                    address: mwm.getWeb3Contract('GymNetwork').options.address,
                    buttonText: this.$t('Add TOKEN to wallet', {token: 'GYMNET'}),
                    symbol: 'GYMNET',
                    decimals: 18,
                    image: 'https://images.gymstreet.io/gymnet.png',
                    network: BinanceSmartChainNetwork,
                },
                {
                    icon: require('~/assets/images/wallets/usdt.png'),
                    address: mwm.getWeb3Contract('USDT').options.address,
                    buttonText: this.$t('Add TOKEN to wallet', {token: 'USDT'}),
                    symbol: 'USDT',
                    decimals: 18,
                    image: 'https://images.gymstreet.io/usdt.png',
                    network: BinanceSmartChainNetwork,
                },
                // {
                //     icon: require('~/assets/images/icons/currencies/btc.png'),
                //     address: mwm.getWeb3Contract('BTCB').options.address,
                //     buttonText: this.$t('Add TOKEN to wallet', {token: 'BTCB'}),
                //     symbol: 'BTCB',
                //     decimals: 18,
                //     image: 'https://images.gymstreet.io/btc.png',
                // },
                // {
                //     icon: require('~/assets/images/icons/btx.svg'),
                //     address: mwm.getWeb3Contract('BitopexToken').options.address,
                //     buttonText: this.$t('Add TOKEN to wallet', {token: 'BITOPEX'}),
                //     symbol: 'BPX',
                //     decimals: 18,
                //     image: 'https://images.gymstreet.io/btx.svg',
                // },
                // {
                //     icon: require('~/assets/images/icons/filecoin-blue.svg'),
                //     address: mwm.getWeb3Contract('FileCoin').options.address,
                //     buttonText: this.$t('Add TOKEN to wallet', {token: 'Filecoin'}),
                //     symbol: `FIL`,
                //     decimals: 18,
                //     image: 'https://images.gymstreet.io/filecoin-blue.svg',
                // },
                ...(ETH_CONTRACTS['Moonberg'][this.$config.chainId] ? [{
                    icon: require('~/assets/images/icons/moonberg.png'),
                    address: ETH_CONTRACTS['Moonberg'][this.$config.chainId],
                    buttonText: this.$t('Add TOKEN to wallet', {token: 'Moonberg'}),
                    symbol: `MNBRG`,
                    decimals: 18,
                    image: 'https://images.gymstreet.io/moonberg.png',
                    network: EthereumNetwork,
                }] : []),
            ]
        }
    }
}
